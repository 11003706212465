.Home {
    width: 1366px;
    /* Set the default width */

    height: auto;
    position: relative;
    margin: 0 auto;
    /* Automatically center horizontally */
    /* margin-right: 20px; */
    /* Set the default margin on the right */
}

/* Media query for smaller screens */
@media screen and (max-width: 1350px) {
    .Home {
        width: 1250px;
        /* Set full width for smaller screens */

        /* Remove margin on the right for smaller screens */
    }
}

@media screen and (max-width: 1200px) {
    .Home {
        width: 1000px;
        /* Set full width for smaller screens */

        /* Remove margin on the right for smaller screens */
    }
}

@media (max-width: 1000px) {
    .Home {
        width: 800px;
        /* Set full width for smaller screens */

        /* Remove margin on the right for smaller screens */
    }
}



.list {
    display: flex;
    justify-content: space-between;
    /* Distribute items evenly along the main axis */
    padding: 0;
    list-style-type: none;
    /* Remove default list styles */
}

.list li {
    flex: 1;
    /* Each list item takes up an equal amount of space */
}


.car-item {
    display: flex;
    flex-direction: column;
}

.car-detail {
    display: flex;
    margin-bottom: 5px;
    /* Optional: Add spacing between entries */
}

.key {
    font-weight: bold;
    /* Optional: If you want to style the keys differently */
    margin-right: 5px;
    /* Optional: Add spacing between key and value */
}

.value {
    /* Optional: Style the value span as needed */
}