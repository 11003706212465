.datatable {}

table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    border: 1px solid #dddddd;
    padding: 8px;
    text-align: left;
}

th {
    background-color: #f2f2f2;
    font-size: 12px;
}



tr:nth-child(4n+1),
tr:nth-child(4n+2) {
    /* Styling for the first two rows */
    background-color: transparent;
    /* Set the background color to transparent */
}

tr:nth-child(4n+3),
tr:nth-child(4n+4) {
    /* Styling for the next two rows */
    background-color: #f2f2f2;
    /* Set the background color for alternating rows */
}

tr:hover {
    background-color: #dddddd;
}



#mundy_name {
    transform: translatey(17px);
    cursor: pointer;
    /* Change cursor to pointer on hover */
    text-decoration: underline;
    /* Add underline */
    color: blue;
    /* Change text color */

}

@media (max-width: 1350px) {

    th,
    td {
        padding: 4px;
        /* Reduce padding for smaller screens */
    }

    th {
        font-size: 10px;

        /* Reduce font size for header cells on smaller screens */
    }
}

@media (max-width: 1240px) {

    th,
    td {
        padding: 3px;
        /* Reduce padding for smaller screens */
    }

    th {
        font-size: 10px;

        /* Reduce font size for header cells on smaller screens */
    }
}

@media screen and (max-width: 900px) {

    th,
    td {
        padding: 3px;
        /* Reduce padding for smaller screens */
    }

    th {
        font-size: 8px;

        /* Reduce font size for header cells on smaller screens */
    }
}


.dialog {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* max-height: var(--page-height); */
    /* min-height: auto; */
    /* background-color: rgba(33, 36, 2, 0.5); */
    z-index: 201;
    justify-content: center;
    align-items: center;
    overflow: auto;
}

.dialog-close {
    cursor: pointer;
    font-size: 20px;
    color: #aaa;
    position: relative;
    left: 400px;
}

.dialog-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    /* Shadow effect */
    max-width: 400px;
    width: 100%;
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
    color: #aaa;
}

.close:hover {
    color: #666;
}